import React, {useState} from 'react';
import {StatesButton, StatesButtonStates, ThreeDotsLoader, ButtonSize} from 'wix-ui-tpa/cssVars';
import {classes} from './PayNowButton.st.css';
import {useErrorMonitor, useFedopsLogger} from '@wix/yoshi-flow-editor';
import {usePaymentsApi} from '../../../../common/components/WithPaymentsApi/WithPaymentsApi';
import {useControllerProps} from '../../Widget/ControllerContext';
import {toMonitorError} from '../../../../domain/utils/errorMonitor.utils';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {FedopsInteractions} from '../../constants';
import {PaymentFlowMonitorBreadcrumbs, PaymentResult} from '../../../../types/paymentRequestApp.types';
import {AddPaymentInfo} from '../../../../domain/utils/analytics.utils';

export const PayNowButtonDataHook = 'pay-now-button';

export const PayNowButton = () => {
  const errorMonitor = useErrorMonitor();
  const localeKeys = useLocaleKeys();
  const [payNowButtonState, setPayNowButtonState] = useState(StatesButtonStates.IDLE);
  const [payNowButtonDisabled, setPayNowButtonDisabled] = useState(false);
  const {
    paymentRequestStore: {paymentRequest, sendClickOnPayNowButtonBIEvent},
    navigationStore: {navigateToThankYouPage, trackEvent},
  } = useControllerProps();
  const fedops = useFedopsLogger();
  const {paymentsApi, activePaymentId} = usePaymentsApi();

  const validateAndSubmit = async () => {
    try {
      setPayNowButtonState(StatesButtonStates.IN_PROGRESS);
      setPayNowButtonDisabled(true);
      sendClickOnPayNowButtonBIEvent(activePaymentId);
      fedops.interactionStarted(FedopsInteractions.ValidatePaymentInteraction);
      const validation = await paymentsApi?.validate();
      fedops.interactionEnded(FedopsInteractions.ValidatePaymentInteraction);

      if (!validation?.isValid) {
        return;
      }

      errorMonitor.addBreadcrumb({
        message: PaymentFlowMonitorBreadcrumbs.validation,
      });
      await paymentsApi?.prepareToPay?.();
      trackEvent(...AddPaymentInfo({activePaymentMethod: activePaymentId}));

      fedops.interactionStarted(FedopsInteractions.Pay);
      const paymentResult = await paymentsApi?.pay?.({orderId: paymentRequest.paymentGatewayOrderId});
      fedops.interactionEnded(FedopsInteractions.Pay);

      errorMonitor.addBreadcrumb({message: PaymentFlowMonitorBreadcrumbs.paid});

      /* istanbul ignore else */
      if (
        paymentResult?.clientStatus === PaymentResult.Approved ||
        /* istanbul ignore next */
        paymentResult?.clientStatus === PaymentResult.Pending
      ) {
        navigateToThankYouPage();
      }
    } catch (e) {
      /* istanbul ignore next */
      errorMonitor.captureException(...toMonitorError(e, PaymentFlowMonitorBreadcrumbs.error));
      /* istanbul ignore next */
      await paymentsApi?.cancelPayment?.();
    } finally {
      setPayNowButtonState(StatesButtonStates.IDLE);
      setPayNowButtonDisabled(false);
    }
  };

  return (
    <StatesButton
      className={classes.payNowButton}
      data-hook={PayNowButtonDataHook}
      disabled={payNowButtonDisabled}
      upgrade={true}
      size={ButtonSize.medium}
      onClick={() => {
        void validateAndSubmit();
      }}
      idleContent={localeKeys.checkout.payLinks.mainCta()}
      state={payNowButtonState}
      inProgressContent={<ThreeDotsLoader className={classes.threeDotButton} />}
      wrapContent
    />
  );
};
