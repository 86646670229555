import {PaymentsWidget} from '@wix/cashier-payments-widget/lazy';
import React from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {usePaymentsApi} from '../../../common/components/WithPaymentsApi/WithPaymentsApi';
import {classes} from './PaymentFlow.st.css';

export enum PaymentWidgetWrapperDataHooks {
  root = 'PaymentWidgetWrapperDataHooks.root',
  emptyRoot = 'PaymentWidgetWrapperDataHooks.emptyRoot',
}

export const PaymentWidgetWrapper = ({
  loaded,
  setLoaded,
}: {
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    paymentStore: {cashierWidgetProps},
    navigationStore: {isSSR},
    paymentRequestStore: {paymentRequest},
  } = useControllerProps();

  const {setPaymentsApi, setActivePaymentId} = usePaymentsApi();
  const {configuration, ...cashierProps} = cashierWidgetProps;

  /* istanbul ignore if */
  if (!cashierWidgetProps || isSSR) {
    return <div className={classes.paymentEmpty} data-hook={PaymentWidgetWrapperDataHooks.emptyRoot}></div>;
  }

  const className = loaded ? classes.paymentOpen : classes.paymentEmpty;

  return (
    <div className={className} data-hook={PaymentWidgetWrapperDataHooks.root}>
      <PaymentsWidget
        {...cashierProps}
        configuration={{...configuration, appSessionId: paymentRequest.id}}
        isFullWidth={true}
        onFullLoad={() => setLoaded(true)}
        onApiInit={setPaymentsApi}
        paymentMethodChanged={setActivePaymentId}
      />
    </div>
  );
};
